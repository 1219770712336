// src/utils.js

const secretKey = 'secret'; // Predefined secret key
const prefix = 'easy-'; // Prefix for compression

// Simple Caesar Cipher for compression with prefix
export const compressText = (text) => {
  const shift = secretKey.length;
  return prefix + text
    .split('')
    .map(char => String.fromCharCode(char.charCodeAt(0) + shift))
    .join('');
};

// Simple Caesar Cipher for decompression and remove prefix
export const decompressText = (compressedText) => {
  const shift = secretKey.length;
  if (compressedText.startsWith(prefix)) {
    const textWithoutPrefix = compressedText.slice(prefix.length);
    return textWithoutPrefix
      .split('')
      .map(char => String.fromCharCode(char.charCodeAt(0) - shift))
      .join('');
  } else {
    throw new Error('Invalid compressed text format');
  }
};
