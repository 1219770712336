// src/compress.js

import React, { useState } from 'react';
import { compressText, decompressText } from './utils';
import './App.css';

function Compress() {
  const [originalText, setOriginalText] = useState('');
  const [compressedText, setCompressedText] = useState('');
  const [decompressedText, setDecompressedText] = useState('');

  const handleCompress = () => {
    const compressed = compressText(originalText);
    setCompressedText(compressed);
  };

  const handleDecompress = () => {
    try {
      const decompressed = decompressText(compressedText);
      setDecompressedText(decompressed);
    } catch (error) {
      console.error('Error decompressing:', error.message);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Text Compressor</h1>
      <div className="mb-4">
        <label className="block mb-2">Original Text:</label>
        <textarea
          className="w-full p-2 border"
          value={originalText}
          onChange={(e) => setOriginalText(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <button
          className="bg-blue-500 text-white py-2 px-4 rounded mr-2"
          onClick={handleCompress}
        >
          Compress
        </button>
        <button
          className="bg-green-500 text-white py-2 px-4 rounded"
          onClick={handleDecompress}
        >
          Decompress
        </button>
      </div>
      <div className="mb-4">
        <label className="block mb-2">Compressed Text:</label>
        <textarea
          className="w-full p-2 border"
          value={compressedText}
          readOnly
        />
      </div>
      <div className="mb-4">
        <label className="block mb-2">Decompressed Text:</label>
        <textarea
          className="w-full p-2 border"
          value={decompressedText}
          readOnly
        />
      </div>
    </div>
  );
}

export default Compress;
