// src/Home.js

import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import { Link } from 'react-router-dom';
import logofav from './logo-fav.svg';
import { decompressText } from './utils';

const Home = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [pages, setPages] = useState([]);
  const [message, setMessage] = useState('');
  const [link, setLink] = useState('');
  const [postLinks, setPostLinks] = useState([]);
  const [showRequestLink, setShowRequestLink] = useState(false);
  
  
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    // Check if the value is already stored in localStorage with the key "easykey"
    let storedValue = localStorage.getItem('easykey');

    if (!storedValue) {
      // If "easykey" does not exist, prompt the user for input
      const userInput = prompt('Please Enter Your Easy Code:', '');

      if (userInput) {
        // Store the user input in localStorage with key "easykey"
        localStorage.setItem('easykey', userInput);

        // Update the stored value with the newly inputted value
        storedValue = userInput;
      }
    }

    // Set the state with the retrieved or newly set input value
    setInputValue(storedValue);
  }, []);

  // ---------------reset------------------------
  // window.alert(inputValue);   
  // localStorage.setItem('easykey', "");


  
  useEffect(() => {
    // let appId = window.prompt('Please Enter Your Easy Code:');
    let appId = inputValue; 
    if (!appId) {
      // appId = '484307450657720'; 
      appId = 'easy-:>:96=:;6<;==86'; 
      // easy-:>:96=:;6<;==86
      setShowRequestLink(true);
    }    
    appId = decompressText(appId);



    window.fbAsyncInit = function() {
      window.FB.init({
        appId: appId.trim(), // Your App ID
        cookie: true,
        xfbml: true,
        version: 'v20.0' // Your API version
      });

      window.FB.getLoginStatus(function(response) {
        statusChangeCallback(response);
      });
    };

    // Load the SDK asynchronously
    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }, []);

  const statusChangeCallback = (response) => {
    if (response.status === 'connected') {
      setIsLoggedIn(true);
      fetchPages(response.authResponse.accessToken);
    } else {
      setIsLoggedIn(false);
    }
  };

  const checkLoginState = () => {
    window.FB.getLoginStatus(function(response) {
      statusChangeCallback(response);
    });
  };

  const fetchPages = (accessToken) => {
    window.FB.api('/me/accounts', function(response) {
      if (response && !response.error) {
        setPages(response.data);
      }
    });
  };

  const login = () => {
    window.FB.login(checkLoginState, {scope: 'pages_show_list, pages_read_engagement, pages_manage_posts'});
  };

  const postToPage = (pageId, accessToken) => {
    window.FB.api(`/${pageId}/feed`, 'POST', { message, link, access_token: accessToken }, function(response) {
      if (response && !response.error) {
        console.log('Post successful:', response);
        const newPostLink = `https://www.facebook.com/${response.id}`;
        setPostLinks(prevPostLinks => [...prevPostLinks, newPostLink]);
      } else {
        console.error('Error:', response.error);
      }
    });
  };

  const postToAllPages = () => {
    pages.forEach((page) => {
      postToPage(page.id, page.access_token);
    });
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };

  return (
    <div>
      {!isLoggedIn && (  
        <div className="login-container">
          {/* logo------------------ */}
          <div style={{backgroundColor: 'white', color: 'black', borderRadius: '25px', paddingLeft: '20px', marginBottom: '10px'}}>
            <div className="logobox" style={{paddingTop: '50px', paddingBottom: '50px'}}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <img className="Animate-logo" src={logofav} alt="Zj"/>
                    </td>
                    <td>
                      <span className="school">Easy SMM</span>
                    </td>
                  </tr>
                  <tr>
                    <td>                    
                    </td>
                    <td>                  
                      {showRequestLink && <button style={{display: inputValue ? 'none' : 'inline-block' , marginRight:'5px'}} className="button requestbtn"><Link style={{textDecoration:'none', color:'white'}}  to='/request'>Request Easy Code</Link></button>}
                      <button className="button" onClick={login}>Login with Facebook</button>                            
                      {/* <button  style={{marginLeft:'5px'}} className="button">users</button> */}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <br />
        </div>
      )}
      {isLoggedIn && (
        <div>            
          {/* logo------------------ */}
          <div style={{backgroundColor: 'white', color: 'black', borderRadius: '25px', paddingLeft: '20px', marginBottom: '10px'}}>
            <div className="logobox" style={{paddingTop: '50px', paddingBottom: '50px'}}>
              <img className="Animate-logo" src={logofav} alt="Zj"/>
              <span className="school">Easy SMM</span>
            </div>
          </div>

          <textarea className="textarea" 
            value={message} 
            onChange={handleMessageChange} 
            placeholder="Write your post here"
            rows="4"
            cols="100"
          />
          <br />
          <input className="link"
            type="text" 
            value={link} 
            onChange={handleLinkChange} 
            placeholder="Enter your link here"
          />
          <br />
          <button className="fb-post-button" onClick={postToAllPages}>Post to All Pages</button>
          <ul>
            {pages.map((page) => (
              <li key={page.id}>                
                <button className="fb-post-button" onClick={() => postToPage(page.id, page.access_token)}>Single Post</button> <span>  </span>
                {page.name}
              </li>
            ))}
          </ul>
          {postLinks.length > 0 && (
            <div>
              <h3>Posted Links:</h3>
              <ul>
                {postLinks.map((postLink, index) => (
                  <li key={index}>
                    <a href={postLink} target="_blank" rel="noopener noreferrer">{postLink}</a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Home;
