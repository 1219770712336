// src/request.js

import React from 'react';
import { Link } from 'react-router-dom';


const Request = () => (
  <div>
    <button style={{marginRight:'5px', background:'#1670E5', border:'none', borderRadius:'25px'}} className="button"><Link style={{textDecoration:'none', color:'white'}}  to='/'>Back To Home</Link></button>

    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdNl5EWZhtRKfrkuL1kVC0-_ngXraessSEZCoHlmL4YyXo9Cw/viewform?embedded=true" width="100%" height="810" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>    
    
  </div>
);

export default Request;
